import React, { Component } from 'react';
import TimerMixin from 'react-timer-mixin';
import { Button, Row, Col } from 'reactstrap';
import Swal from 'sweetalert2';
import DialogInformationNotificationsBlocked from './DialogInformationNotificationBlocked';
import toast from './toast';
const styleGeneral = {
    padding: '3px'
};

class ContainerAlertSystem extends Component {

    state = {
        isOnline: true,
        showAnimationOnline: false,
        notificationGranted: false,
        notificationViewClosed: false,
        isOpenModalInfoNotificacion: false,
        isOpenInfoNotification: false,
    }

    constructor(props){
        super(props);

        this.timerAnimationOnline = null;
        // this.notifyMe = this.notifyMe.bind(this);
        this.solicitarActivarNotificaciones = this.solicitarActivarNotificaciones.bind(this);
        this.iniciarEscuchaPermisosNotificaciones = this.iniciarEscuchaPermisosNotificaciones.bind(this);
        this.hasPermissions = this.hasPermissions.bind(this);
        this.closeInfoNotification = this.closeInfoNotification.bind(this);
    }

    componentDidMount(){

        window.addEventListener('offline', (e) => {
            console.log('offline');
            this.setState({isOnline: false, showAnimationOnline: false}, () => {
                this.removeAnimationOnline();
            });
        });
            
        window.addEventListener('online', (e) => { 
            console.log('online');
            this.setState({isOnline: true, showAnimationOnline: true}, () => {
                this.startAnimationOnline();
            });
        });

        var notificationGranted = this.hasPermissions();
        this.setState({
            notificationGranted,
            notificationViewClosed: notificationGranted ? true : false,
        });

        this.iniciarEscuchaPermisosNotificaciones();
    }

    removeAnimationOnline(){
        if(this.timerRefreshId != null){
            this.setState({ showAnimationOnline: false });
			TimerMixin.clearInterval(this.timerRefreshId);
		}
    }

    startAnimationOnline(){
        this.timerRefreshId = TimerMixin.setInterval(() => {
			this.removeAnimationOnline();
		}, 4000);
    }

    hasPermissions(){
        var Notification = window.Notification || window.mozNotification || window.webkitNotification;
        return Notification && Notification.permission == 'granted';
    }

    iniciarEscuchaPermisosNotificaciones(){
        
        const Notification = window.Notification || window.mozNotification || window.webkitNotification;
        var was_questioned = false;
        
        if(Notification && Notification.permission == 'default') was_questioned = true;
        if(Notification == undefined) return;
        
        var self = this;
        
        Notification.requestPermission(function (permission) {
            
            if ('permissions' in navigator) {
                navigator.permissions.query({ name : 'notifications' }).then((notificationPerm) => {

                    notificationPerm.onchange = () => {
                        self.setState({
                            notificationGranted: notificationPerm.state == 'granted',
                            notificationViewClosed: notificationPerm.state == 'granted',
                        });
                    };

                    if(self.hasPermissions()){
                        self.setState({
                            notificationGranted: true,
                            notificationViewClosed: true
                        });
                    }

                }).catch((error) => {
                    console.log(error);
                });
            }
        });
    }

    solicitarActivarNotificaciones(){
        var Notification = window.Notification || window.mozNotification || window.webkitNotification;
        if(Notification && Notification.permission == 'granted'){
            this.setState({
                notificationGranted: true,
                notificationViewClosed: true
            });
        }else if(Notification && Notification.permission == 'denied'){
            // Swal.fire({
            //     title: 'Denegado',
            //     text: 'Los permisos estan bloqueados en el navegador. Activelos directamente o consulte a soporte técnico',
            //     icon: 'warning',
            // });
            this.setState({
                isOpenInfoNotification: true
            })
        }else{
            this.props.onRequestNotificationPermission();
        }
    }

    closeInfoNotification(){
        this.setState({
            isOpenInfoNotification: false
        })
    }

    render() {
        return (
            <div style={{ zIndex: '9999', position: 'fixed', width: '100%'}}>
                
                {
                    !this.state.isOnline && (
                        <div className="bg-danger text-white text-center animated fadeIn" style={styleGeneral}>
                            <strong><i className="fa fa-wifi"></i> No tiene conexión a internet!</strong>
                        </div>
                    )
                }

                {
                    this.state.showAnimationOnline && (
                        <div className="bg-success text-white text-center animated fadeIn" style={styleGeneral}>
                            <strong><i className="fa fa-check"></i> Está conectado a internet!</strong>
                        </div>
                    )
                }

                {
                    !this.state.notificationGranted && !this.state.notificationViewClosed && (
                        <div className="bg-warning text-white text-center animated fadeIn" style={styleGeneral}>
                            <Row>
                                <Col xs="12" sm="12" md="10" lg="10">
                                    <strong>
                                        <i className="fa fa-bell"></i> No está activada la recepción de notificaciones.&nbsp;&nbsp;
                                        <a href="#" onClick={e => { e.preventDefault(); this.solicitarActivarNotificaciones(); }}>Activar</a>
                                    </strong>
                                </Col>
                                <Col xs="12" sm="12" md="2" lg="2" className="text-right">
                                    <Button type="button" onClick={e => {
                                        this.setState({ notificationViewClosed: true  });
                                    }} close />
                                </Col>
                            </Row>
                        </div>
                    )
                }

                {
                    this.state.isOpenInfoNotification ?
                    <DialogInformationNotificationsBlocked
                        isOpen={this.state.isOpenInfoNotification}
                        onClose={this.closeInfoNotification}
                    />
                    : null
                }

            </div>
        );
    }
}

export default ContainerAlertSystem;