import { utils } from '../../utils';

const initialState = { 
    update_badge: '',
};

const reducer = (state = initialState, action) => {
    const newState = {...state};
    if (action.type == 'UPDATE_BADGE_MENU') {
        newState.update_badge = utils.randomString(10);
        newState.tipo = action.tipo;
        newState.mid = action.mid;
        newState.id = action.mid || action.id;
        newState.accion = action.accion;
    }

    return newState;
};

export default reducer;