export default {
    company: {
        name     : 'Andamios Espinosa S.A. de C.V.',
        description : 'Renta y venta de equipo y maquinaria ligera para la construcción',
        address  : 'Calle 45 #500 entre 6 y 4 Col. Manuel Ávila Camacho, Mérida, Yucatán, México',
        phone    : '+52  (999) 926-2793 / 374-3673',
        // phone    : '+52 999-926-2793 y +52 999-374-3673',
        email    : 'ventas@andamiosespinosa.com',
        location : { lat: 20.976125, lng: -89.583279 },
        social: {
            facebook  : 'https://www.facebook.com/andamiosespinosa/',
            twitter   : 'https://twitter.com/and__espinosa',
            instagram : 'https://www.instagram.com/andamios_espinosa/',
            linkedin  : '',
        },
    },
    KEY_SHOWN_ALERT_RECOVERY_EMAIL : 'shown_alert_recovery_email'
}