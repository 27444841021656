
import {Component} from 'react';
import { connect } from 'formik';

class ErrorFocus extends Component {

    componentDidUpdate(prevProps) {
    
        const { isSubmitting, isValidating, errors } = prevProps.formik;
        const keys = Object.keys(errors);
        
        if (keys.length > 0 && isSubmitting && !isValidating) {
            
            for( var i = 0; i < keys.length; i++ ){                
                //const selector = `[id="${keys[0]}"]`;
                const selector     = `[name="${keys[i]}"]`;
                const errorElement = document.querySelector(selector);                
                if( errorElement ){                
                    errorElement.focus();
                    return;
                }
            }
        }
    }

    render(){
        return null;
    }
}

export default connect(ErrorFocus);