import React from 'react';

const MultiLineText = ({text,  ...props}) => {

    const lines = (text || '').split('\n')

    return (
        <div>
            { lines.map((line, index) => line ? <p className='p-0 m-0' key={index}>{line}</p> : <br key={index}/>)}
        </div>
    );
};

export default MultiLineText;