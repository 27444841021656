
const initialState = { 
    carrito: 0,
    requestId: '',
};

const reducer = (state = initialState, action) => {
    const newState = {...state};
    if (action.requestId){ newState.requestId = action.requestId; }

    if(action.type == 'CARRITO_UPDATE'){ newState.carrito = 1; }
    if (action.type == 'CARRITO_CLEAN') { newState.carrito = 0; }

    return newState;
};

export default reducer;