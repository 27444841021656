import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { routesMap } from '.';

class MyBreadcrumb extends Component {

    state = {
        items: [
            { icon: 'fa fa-home', uri: routesMap.dashboard.path, title: 'Panel de control'  },
        ]
    }

    static getDerivedStateFromProps(nextProps, prevState){
        var current = [...(prevState.items || [])];
        if(current.length > 0){
            current.shift();
        }
        var next = [...(nextProps.items || [])];

        // si uno no existe en el otro
        var requiereUpdate = false;
        for(var i = 0; i< current.length ; i++){
            var existeItem = next.find(item => { return item.uri == current[i].uri; });
            if(!existeItem){
                requiereUpdate = true;
                break;
            }
        }

        if(!requiereUpdate){
            for(var i = 0; i< next.length ; i++){
                var existeItem = current.find(item => { return item.uri == current[i].uri; });
                if(!existeItem){
                    requiereUpdate = true;
                    break;
                }
            }
        }

        if(requiereUpdate){
            return {
                items: [
                    { icon: 'fa fa-home', uri: routesMap.dashboard.path, title: 'Panel de control'  },
                    ...nextProps.items
                ]
            }
        }

        return null;
      }

    render() {
        return (
            <div className="container-fluid container-breadcrumb">
                <Breadcrumb>
                    {
                        (this.state.items || []).map((item, index) => {
                            return <BreadcrumbItem key={'item_bread_'+index} active={ (this.state.items || []).length == (index+1) }>
                                { ( item.disabled ||  (this.state.items || []).length == (index+1) ) && (
                                    <><i className={item.icon}></i> {item.title}</>
                                ) }

                                {
                                    !( item.disabled ||  (this.state.items || []).length == (index+1) ) && (
                                        <Link to={item.uri}><i className={item.icon}></i> { item.title }</Link>
                                    )
                                }
                            </BreadcrumbItem>
                        })
                    }
                    
                </Breadcrumb>
            </div>
        );
    }
}

export default MyBreadcrumb;