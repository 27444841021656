import api from './api';
import toast from './toast';
import session from './session';
import ModalConfirmacion from './ModalConfirmacion';
import FormatoFecha from './FormatoFecha';
import filters from './filters';
import PaginateButtonGroup from './PaginateButtonGroup';
import TablePaginate from './TablePaginate';
import FormComponent from './FormComponent';
import mapaUtils from './mapaUtils';
import utils from './utils';
import MyDropZone from './MyDropZone';
import Validation from './Validation';
import InputGroupFile from './InputGroupFile';
import MySearchAutocomplete from './MySearchAutocomplete';
import ModalConfirmacionV2 from './ModalConfirmacionV2';
import ModalPreviewDocument from './ModalPreviewDocument';
import MyBreadcrumb from './MyBreadcrumb';
import routesMap from './routesMap';
import ErrorFocus from './ErrorFocus';
import {messaging} from './init-fcm';
import ContainerAlertSystem from './ContainerAlertSystem';
import LogoAndamiosEspinosa from '../assets/img/brand/logo.svg';
import LogoAndamiosEspinosa2022 from '../assets/img/brand/logo_2022.png';
import DefaultProfileImage from '../assets/img/man.png';
import ScrollToTop from './ScrollToTop';
import MultiLineText from './MultiLineText';
import constants from './constants';

const ButtonSubmit = FormComponent.ButtonSubmit;
const MyLink = FormComponent.MyLink;
const LoaderComponent = FormComponent.LoaderComponent;
const ConfirmModal = FormComponent.ConfirmModal;
const PermisoWrapper = FormComponent.PermisoWrapper;
const MyAutocomplete = FormComponent.MyAutocomplete;
const EstatusBoxDesign = FormComponent.EstatusBoxDesign;

export { 
    api, 
    toast, 
    session, 
    ModalConfirmacion, 
    FormatoFecha, 
    filters, 
    PaginateButtonGroup,
    TablePaginate,
    ButtonSubmit,
    MyLink,
    LoaderComponent,
    mapaUtils,
    utils,
    MyDropZone,
    ConfirmModal,
    PermisoWrapper,
    Validation,
    InputGroupFile,
    MyAutocomplete,
    EstatusBoxDesign,
    MySearchAutocomplete,
    ModalConfirmacionV2,
    ModalPreviewDocument,
    ErrorFocus,
    messaging,
    LogoAndamiosEspinosa,
    LogoAndamiosEspinosa2022,
    DefaultProfileImage,
    MyBreadcrumb,
    ContainerAlertSystem,
    routesMap,
    constants,
    ScrollToTop,
    MultiLineText,
};
