import moment from 'moment';
import utils from './utils';

var filters = {
    number: (value, decimalPlaces) => {
        var val = parseFloat(value || 0) || 0;
        return val.toLocaleString(navigator.language, { minimumFractionDigits: ( decimalPlaces || 2 ) });
    },
    currency: (value, decimalPlaces) => {
        return "$"+filters.number(value, decimalPlaces || 2);
    },
    date: (value, format) => {
        if(value){
            return moment(value).format(format ? format : 'DD/MM/YYYY');
        }
        return '';
    },
    estatusRentaEquipo: (identificador) => {
        if (identificador) {
            var items = utils.getListaEstatusRentaEquipo();
            var found = items.find(item2 => { return item2.id == identificador });
            if (found) {
                return found.label;
            }
        }
        return '';
    },
    estatusRenta: (identificador) => {
        if (identificador) {
            var items = utils.getListaEstatusRenta();
            var found = items.find(item2 => { return item2.id == identificador });
            if (found){
                return found.label;
            }
        }
        return '';
    },
    tipoMovimientoBodega: (identificador) => {
        if (identificador) {
            var items = utils.getListaTipoMovimientoBodega();
            var found = items.find(item2 => { return item2.id == identificador });
            if (found) {
                return found.label;
            }
        }
        return '';
    },
    capitalize :  ( input ) => {
        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase().replace(/_/g, ' ') : '';        
    }
};

export default filters;