import React, { useState, useEffect } from 'react';
import TimerMixin from 'react-timer-mixin';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { browserName, isMobile } from 'react-device-detect';
import './DialogInformationNotificationBlockedStyle.scss';
import helpChrome1a from '../assets/img/help/chrome_1a.png';
import helpChrome2a from '../assets/img/help/chrome_2a.png';
import helpChrome1 from '../assets/img/help/chrome_1.png';
import helpChrome2 from '../assets/img/help/chrome_2.png';
import helpFirefox1 from '../assets/img/help/firefox_1.png';
import helpFirefox2 from '../assets/img/help/firefox_2.png';
import helpFirefox3 from '../assets/img/help/firefox_3.png';
import helpEdge1 from '../assets/img/help/edge_1.png';
import helpEdge2 from '../assets/img/help/edge_2.png';
import helpEdge3 from '../assets/img/help/edge_3.png';
import helpEdge4 from '../assets/img/help/edge_4.png';
import helpSafari1 from '../assets/img/help/safari_1.png';
import helpSafari2 from '../assets/img/help/safari_2.png';

const DialogInformationNotificationsBlocked = ({ isOpen, onClose }) => {

    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {

        console.log(browserName)

        if(browserName == 'Chrome'){
            setActiveTab(1)
        }else if(browserName == 'Firefox'){
            setActiveTab(2)
        }else if(browserName == 'Edge'){
            setActiveTab(3)
        }else if(browserName == 'Safari' || browserName == 'Mobile Safari'){
            setActiveTab(4)
        }

    }, [])

    return (
        <Modal fade={false} style={{width: isMobile ? '100%' : '80%', maxWidth: '1200px' }} id="dialog-info-notification" size="lg" isOpen={ isOpen } toggle={onClose}>
            {/* <ModalHeader toggle={onClose}>Notificaciones bloqueadas</ModalHeader> */}
            <ModalBody>

                <h4>Las notificaciones están bloqueadas en el navegador</h4>
                <p>Sigue las instrucciones de tu navegador para activarlas manualmente</p>

                <Nav tabs fill>
                <NavItem>
                    <NavLink
                    className={  activeTab == 1 ? "active" : ""}
                    onClick={function noRefCheck(){
                        setActiveTab(1)
                    }}
                    >
                    <i className="fa fa-chrome"></i> Google Chrome
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    className={  activeTab == 2 ? "active" : ""}
                    onClick={function noRefCheck(){ setActiveTab(2) }}
                    >
                    <i className="fa fa-firefox"></i> Mozilla Firefox
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    className={  activeTab == 3 ? "active" : ""}
                    onClick={function noRefCheck(){ setActiveTab(3) }}
                    >
                    <i className="fa fa-edge"></i> Microsoft Edge
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                    className={  activeTab == 4 ? "active" : ""}
                    onClick={function noRefCheck(){ setActiveTab(4) }}
                    >
                    <i className="fa fa-safari"></i> Safari
                    </NavLink>
                </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <Row>
                        <Col sm="12">

                            <strong>Opción 1</strong>

                            <p>1.1 Selecciona la opción que se muestra en la imagen y permite las notificaciones.</p>
                            <img src={helpChrome1a} alt="Paso 1a Chrome" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />


                            <p>1.2. Aparecerá un mensaje para actualizar la página.</p>
                            <img src={helpChrome2a} alt="Paso 1.2a Chrome" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <strong>Opción 2</strong>

                            
                            <p>2.1. Si la opción anterior no se muestra, accede a las configuraciones del sitio</p>
                            <img src={helpChrome1} alt="Paso 1 Chrome" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <p>2.2 Busca el permiso para notificaciones y selecciona la opción "Permitir". Regresa y recarga la página</p>
                            <img src={helpChrome2} alt="Paso 2 Chrome" className='img img-responsive' style={{width: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <p><strong>Para más información puedes consultar la página oficinal de Google Chrome <a href="https://support.google.com/chrome/answer/3220216?hl=es" rel='noopener' target='_blank'>https://support.google.com/chrome/answer/3220216?hl=es</a></strong></p>

                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={2}>
                    <Row>
                        <Col sm="12">

                            <p>1. Borra el permiso actual para que se solicite nuevamente</p>
                            <img src={helpFirefox1} alt="Paso 1 Firefox" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <p>2. Te indicará el siguiente mensaje. Recarga la página (Puede ser mediante el teclado: en Windows con "F5", en Mac OS con "Command + R" )</p>
                            <img src={helpFirefox2} alt="Paso 2 Firefox" className='img img-responsive' style={{width: '100%', height: 'auto'}}/>
                            
                            <br /><br />
                            <br /><br />

                            <p>3. Oprime el botón de activar notificaciones y te aparecerá un dialogo para permitir</p>
                            <img src={helpFirefox3} alt="Paso 3 Firefox" className='img img-responsive' style={{width: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <p><strong>Para más información puedes consultar la página de Mozilla Firefox <a href="https://support.mozilla.org/es/kb/notificaciones-push-en-firefox?as=u&utm_source=inproduct" rel='noopener' target='_blank'>https://support.mozilla.org/es/kb/notificaciones-push-en-firefox?as=u&utm_source=inproduct</a></strong></p>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={3}>
                    <Row>
                        <Col sm="12">
                            <strong>Opción 1</strong>
                            <p>1.1. Selecciona la opción que se muestra en la imagen y permite las notificaciones.</p>
                            <img src={helpEdge1} alt="Paso 1a Edge" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />


                            <p>1.2. Aparecerá un mensaje para actualizar la página.</p>
                            <img src={helpEdge2} alt="Paso 1.2a Edge" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <strong>Opción 2</strong>

                            <p>2.1. Si la opción anterior no se muestra, selecciona la opción "Permisos para este sitio"</p>
                            <img src={helpEdge3} alt="Paso 1 Edge" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <p>2.2 Busca el permiso para notificaciones y selecciona la opción "Permitir". Regresa y recarga la página</p>
                            <img src={helpEdge4} alt="Paso 2 Edge" className='img img-responsive' style={{width: '100%', height: 'auto'}}/>

                            {/* <br /><br />
                            <br /><br />

                            <p><strong>Para más información puedes consultar la página oficinal de Google Chrome <a href="https://support.google.com/chrome/answer/3220216?hl=es" rel='noopener' target='_blank'>https://support.google.com/chrome/answer/3220216?hl=es</a></strong></p> */}
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={4}>
                    <Row>
                        <Col sm="12">
                            <p>1. Accede a las preferencias del navegador</p>
                            <img src={helpSafari1} alt="Paso 1 Safari" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>

                            <br /><br />
                            <br /><br />

                            <ol>
                                <li>Selecciona la pestaña de "Sitios Web"</li>
                                <li>En el panel lateral izquierdo busca "Notificaciones"</li>
                                <li>Ubica el dominio andamiosespinosa.com</li>
                                <li>Selecciona la opción permitir</li>
                                <li>Finalmente cierra la ventana de configuración y actualiza la página</li>
                            </ol>
                            <img src={helpSafari2} alt="Paso 1 Safari" className='img img-responsive' style={{width: 'auto', maxWidth: '100%', height: 'auto'}}/>
                        </Col>
                    </Row>
                </TabPane>
                </TabContent>
                
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={e => onClose()} color="secondary">Aceptar</Button>
            </ModalFooter>
        </Modal>
    )

}

export default DialogInformationNotificationsBlocked;