export default {
  home: {
    path: "/",
    name: "Inicio",
  },
  infoEmpresa: {
    path: "/empresa",
    name: "Empresa",
  },
  infoProductos: {
    path: "/productos",
    name: "Productos",
  },
  infoFacturas: {
    path: "/facturas",
    name: "Facturas",
  },
  infoContacto: {
    path: "/contacto",
    name: "Contácto",
  },
  infoFAQ: {
    path: "/faq",
    name: "Preguntas frecuentes",
  },
  privacidad: {
    path: "/privacy",
    name: "Privacidad",
  },
  privacidadApp: {
    path: "/app-privacy-policy",
    name: "Política de privacidad app",
  },
  eliminacionDeDatos: {
    path: "/request-delete-data",
    name: "Eliminacion de datos",
  },
  login: {
    path: "/login",
    name: "Login",
  },
  requestRecoverPassword: {
    path: "/request-recover-password",
    name: "Solicitar recuperación de contraseña",
  },
  recoverPassword: {
    path: "/recover-password",
    name: "Recuperar contraseña",
  },
  rentaNotaSE: {
    path: "/nota-se",
    name: "Nota de Renta",
  },
  defaultLayout: {
    path: "/client/",
    name: "Inicio Cliente",
  },
  dashboard: {
    path: "/client/dashboard",
    name: "Panel de control",
  },
  catalogoEquipo: {
    path: "/client/catalogo-equipos",
    name: "Catálogo de equipos",
  },
  equiposRentados: {
    name: "Rentas activas",
    path: "/client/equipos-rentados",
  },
  solicitud: {
    name: "Pedidos en Proceso",
    long_name: "Pedidos en Proceso",
    path: "/client/solicitudes",
  },
  crearSolicitudSalida: {
    name: "Nueva solicitud",
    path: "/client/solicitudes/renta",
  },
  crearSolicitudEntrada: {
    name: "Nueva solicitud",
    path: "/client/solicitudes/devolucion",
  },
  singleSolicitud: {
    name: "Detalles solicitud",
    path: "/client/solicitudes/:id",
  },
  trackingSolicitud: {
    name: "Rastreo de pedido",
    path: "/client/solicitudes/rastreo/:id",
  },
  obras: {
    name: "Obras",
    path: "/client/obra",
  },
  historicoSolicitudes: {
    name: "Historial de rentas",
    path: "/client/historial-rentas",
  },
  singleHistoricoSolicitud: {
    name: "Ver movimiento",
    path: "/client/historial-rentas/:id",
  },
  perfil: {
    name: "Perfil",
    path: "/client/perfil",
  },
  facturas: {
    name: "Facturas",
    path: "/client/facturas",
  },
  perfilUsuariosSecundarios: {
    name: "Usuarios secundarios",
    path: "/client/perfil?tab=usuarios_secundarios",
  },
};
