import { createStore, combineReducers } from 'redux';
import carrito from './reducers/carrito';
import badge from './reducers/badge';
import movimiento from './reducers/movimiento';
import datos from './reducers/datos';
import updateRentas from './reducers/updateRentas';

const reducer = combineReducers({ carrito, badge, movimiento, datos, updateRentas });
const store = createStore(reducer);

export default store;