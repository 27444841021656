import { utils } from '../../utils';

const initialState = { 
    count_solicitudes: 0,
    count_rentas: 0
};

const updateRentas = (state = initialState, action) => {
    const newState = {...state};
    if (action.type == 'UPDATE_RENTAS') {
        newState.count_solicitudes = action.count_solicitudes
        newState.count_rentas = action.count_rentas
    }

    return newState;
};

export default updateRentas;