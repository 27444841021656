import { utils } from '../../utils';

const initialState = { 
    update_perfil: '',
};

const reducer = (state = initialState, action) => {
    const newState = {...state};
    if (action.type == 'UPDATE_PERFIL') {
        newState.update_perfil = utils.randomString(10);
    }

    return newState;
};

export default reducer;