import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {session} from './index';
import Autocomplete from 'react-autocomplete';

var verificar_tiene_modulo = (mod, modulos, typeComparisson, crear, editar, eliminar) => {

    var esArray = !(typeof mod == 'string');
    var found = (modulos || []).find((item) => {
        // return item.identificador === mod;
        return (!esArray && item.identificador == mod)  || (esArray && mod.indexOf(item.identificador) !== -1);
    });
    if (found) {
        if (crear || editar || eliminar) {
            if ((crear && found.crear) ||
                (editar && found.editar) ||
                (eliminar && found.eliminar)
            ) { return true; }
        } else { return true; }
    }
    return false;
};

var FormComponent = {
    PermisoWrapper: (props) => {
        var mod = props.modulo;
        if(mod){
            var modulos = session.getModulos(true);
            if(verificar_tiene_modulo(mod, modulos, props.type, props.crear, props.editar, props.eliminar)){
                return (<>{props.children}</>);
            }else{
                return (<></>);
            }
        }else{
            return (props.children);
        }
        // if(mod){
        //     var modulos = session.getModulos(true);
        //     var found = (modulos || []).find((item) => {
        //         return item.identificador === mod;
        //     });
        //     if(found){
        //         if(props.crear || props.editar || props.eliminar){
        //             if((props.crear && found.crear) ||
        //                 (props.editar && found.editar) ||
        //                 (props.eliminar && found.eliminar)
        //             ){
        //                 return(<span>{props.children}</span>);
        //             }else{
        //                 return(<span></span>);
        //             }
        //         }else{
        //             return(<span>{props.children}</span>);
        //         }
        //     }else{
        //         return(<span></span>);
        //     }
        // }else{
        //     return(props.children);
        // }
    },
    ButtonSubmit: (props) =>{
        return (
            <button type="submit" className="btn btn-success" disabled={props.loading || props.disabled}>
                { props.loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : <i className={props.iconClassName ? props.iconClassName : 'fa fa-save' }></i> }
                {' '}
                { props.loading ? (props.loadingMessage ? props.loadingMessage : 'Espere por favor') : ( props.primaryButtonText ? props.primaryButtonText : (props.isUpdate ? 'Actualizar' : 'Guardar') ) }
            </button>
        );
    },
    
    MyLink: (props) => {
        return (
            props.disabled ?
                <button disabled={true} className={props.className}>{props.children}</button>
            : 
            <Link to={props.to} className={props.className}>{props.children}</Link>
        );
    },
    LoaderComponent: () => {
        return (
            <div className="animated fadeIn pt-3 text-center"><i className="fa fa-circle-o-notch fa-spin"></i> Cargando, espere un momento por favor...</div>
        );
    },
    ConfirmModal: (options) => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='custom-ui' style={{zIndex: '9999 !important'}}>
                        <h1 style={{
                            textAlign: 'center'
                        }}>{options.title || 'Confirmación eliminar'}</h1>
                        <p style={
                            {
                                maxWidth: '500px',
                                textAlign: 'center'
                            }
                        } dangerouslySetInnerHTML={{__html: options.message}}>
                        {/* {options.message || 'Confirmación eliminar'} */}
                        </p>
                        
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                            <button className="btn btn-block btn-secondary" onClick={() => {
                                if(options.onCancel){
                                    options.onCancel();
                                }
                                onClose();
                            }}>{options.cancelButtonLabel || 'Cancelar'}</button>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                <button
                                    className={options.confirmButtonClass ? options.confirmButtonClass : 'btn btn-danger btn-block'}
                                    onClick={() => {
                                        options.onConfirm();
                                        onClose();
                                    }}
                                >
                                    <i className="fa fa-trash-o"></i> { options.confirmButtonLabel || 'Eliminar'}
                                </button>
                            </Col>
                        </Row>
                        
                  </div>
                );
            }
        })
    },
    MyAutocomplete: (tagProps) => {
        return (
            <Autocomplete
                wrapperStyle={{
                    display: 'block',
                }}
                inputProps={{
                    id: tagProps.id || 'state-autocomplete',
                    placeholder: tagProps.placeholder || 'Buscar',
                    className:'form-control',
                    onFocus: () => {
                        console.log("====> focused");
                        // this.handleAutocompleteSearch();
                    }
                }}
                menuStyle={
                    {
                        borderRadius: '5px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0)',
                        background: 'rgba(255, 255, 255, 1)',
                        // padding: '2px 0',
                        fontSize: '90%',
                        position: 'fixed',
                        overflow: 'auto',
                        maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                    }
                }
                value={ tagProps.value }
                items={ tagProps.items }
                getItemValue={(item) => item[tagProps.field || 'autocomplete_field'] }
                onSelect={ (value, item) => {
                    console.log("========> item clicked");
                    tagProps.onSelect(value, item)
                    // this.selectedValue = item;
                    // this.setState({
                    //     autocomplete_value: value,
                    //     autocomplete_items: [ item ]
                    // });
                }}
                onChange={(event, value) => {
                    tagProps.onChange(value);
                    // this.setState({
                    //     autocomplete_value: value
                    // }, () => {
                    //     this.handleAutocompleteSearch();
                    // });
                }}
                renderItem={(item, isHighlighted) => (
                    <div
                        style={
                            {
                                // padding: '10px',
                                fontSize: '14px',
                                cursor: 'pointer',
                                border: 'solid thin #c1c1c1',
                                // textOverflow: 'ellipsis',
                                // overflow: 'hidden', 
                                maxWidth: '600px',
                                // whiteSpace: 'nowrap',
                                zIndex: '9999 !important',
                            }
                        }
                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                        key={item.id}
                    >{item[tagProps.field || 'autocomplete_field']}</div>
                )}
                renderInput={
                    (props) => {
                        return <div className="input-group">
                            <input {...props}></input>
                            <div className="input-group-append">
                                <button disabled={tagProps.loading} type="button" onClick={
                                    (e) => {
                                        tagProps.onClear();
                                        // this.selectedValue = null;
                                        // this.setState({
                                        //     autocomplete_value: '',
                                        //     autocomplete_items: [],
                                        // });
                                    }
                                } className="btn btn-secondary"><i className={tagProps.loading ? 'fa fa-spin fa-circle-o-notch' : 'fa fa-times '}></i> </button>
                            </div>
                        </div>
                    }
                }
            >
            </Autocomplete>
        )
    },
    EstatusBoxDesign: (props) => {
        let className = 'bg-info';
        if(props.estatus == 'activo') className = 'bg-success'
        if(props.estatus == 'inactivo' || props.estatus == 'cancelado') className = 'bg-danger'
        if(props.estatus == 'pendiente') className = 'bg-info'
        return (
            <span className={'badge px-2 py-2 '+className}>{props.children}</span>
        )
    },
};

export default FormComponent;