import * as firebase from 'firebase/app';
import 'firebase/messaging';
import config from '../config.json'

var messaging  = null;
if(firebase.messaging.isSupported()){
    const initializedFirebaseApp = firebase.initializeApp(config.fcm);
    
    // initializedFirebaseApp.analytics();
    
    messaging = initializedFirebaseApp.messaging();
    
    
    // Project Settings => Cloud Messaging => Web Push certificates
    messaging.usePublicVapidKey(config.fcm_web_push_certificate);
    // export { messaging };
}else{
    messaging = null;
}

export { messaging };
