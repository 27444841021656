import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap';

class ModalConfirmacionV2 extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <Modal
                size={this.props.size || 'md'}
                className={ 'modal-'+(this.props.type || 'primary')}
                backdrop={this.props.backdrop || true}
                isOpen={ this.props.isOpen } 
                toggle={this.props.onClose}>
                <ModalHeader toggle={this.props.onClose}>{this.props.title || 'Confirmación'}</ModalHeader>
                <ModalBody>
                    {this.props.children}
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" onClick={this.props.onClose}>{this.props.btnCancelar || ' Cancelar'}</Button>
                    <Button type="button" color={this.props.type || 'primary'} onClick={(e) => {
                        this.props.onClose(e, true)
                    }}> <i className="fa fa-check"></i>{this.props.renderBtnAceptar ? this.props.renderBtnAceptar() : (this.props.btnAceptar || ' Aceptar')}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default ModalConfirmacionV2;