import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink, Row, Col, Input, CustomInput, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

class PaginateButtonGroup extends Component {

    constructor(props) {
        super(props);
        this.generarListaBtns = this.generarListaBtns.bind(this);

        var total = props.total || 0;
        var page = props.page || 1;
        var count = props.count || 1;
        var numberPages = total == 0 ? 0 : ( Math.ceil(total / count) );
        this.state = {
            page: page,
            total: total,
            count: count,
            pages: this.generarListaBtns(page, numberPages),
            counts: this.props.counts || [2,5,10,20,50,100,150],
            label_counts: this.props.labelCounts || 'Mostrar',
        };
    
        this.handleChange = (event) => {
            var name = event.target.name;
            var dataState = {
                [event.target.name]: event.target.value,
            };

            if(name == 'count'){
                dataState.page = 1;
            }

            this.setState(dataState, () => {
                if(name == 'count' || name == 'page'){
                    this.props.onChange(this.state.page, this.state.count);
                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        var total = nextProps.total || 0;
        var page = nextProps.page || 1;
        var count = nextProps.count || 1;
        var numberPages = total == 0 ? 0 : ( Math.ceil(total / count) );

        this.setState({
            page: page,
            total: total,
            count: count,
            pages: this.generarListaBtns(page, numberPages),
            counts: nextProps.counts || [2,5,10,20,50,100,150],
            label_counts: nextProps.labelCounts || 'Mostrar',
        });
    }

    generarListaBtns(currentPage, tPages){
        var btns = [];
        if(tPages > 0){
            if(tPages < 10){
                for (let i = 1; i <= tPages; i++) {
                    btns.push({
                        page: i,
                        current: currentPage == i,
                    });
                }
            }else{
                var maxIntervalo = 4;
                var middle = maxIntervalo / 2;
                if(currentPage>=1 && currentPage <=maxIntervalo){
                    for (let i = 1; i <= maxIntervalo+1; i++) { 
                        btns.push({
                            page: i,
                            current: currentPage == i,
                        }); 
                    }
                    
                    btns.push({ page: '...', disabled: true });

                    for (let i = middle; i >= 0 ; i--) {
                        btns.push({ page: tPages - i });
                    }
    
                }else if(currentPage >= (tPages-maxIntervalo)){
                    
                    for (let i = 1; i <= middle ; i++) {
                        btns.push({
                            page: i,
                            current: i == currentPage,
                        });
                    }

                    btns.push({page: '...', disabled: true});
                
                    for (let i = maxIntervalo; i >= 0; i--) { 
                        btns.push({
                            page: tPages - i,
                            current: (tPages - i) == currentPage,
                        });
                    }
                }else{
                    btns.push({ page: 1 });
                    btns.push({ page: '...', disabled: true });
    
                    for (let i = -middle; i <= middle ; i++) {
                        btns.push({
                            page: currentPage +i,
                            current: (currentPage +i) == currentPage,
                        });
                    }

                    btns.push({page: '...', disabled: true});
                    btns.push({ page: tPages, disabled: false });
                }
            }
        }
        return btns;
    }

    render() {

        const Buttons = () => {
            return <Pagination aria-label="Page navigation example">
                {
                    this.state.pages.length > 2 ?
                        <PaginationItem disabled={this.state.pages[0].disabled || this.state.pages[0].current }>
                            <PaginationLink  first onClick={(event) => {
                                this.handleChange({target: { name: 'page', value: 1 }});
                            }} />
                        </PaginationItem>
                    : null
                }
                {
                    this.state.pages.map((item, index) => {
                        return <PaginationItem key={index} active={item.current} disabled={item.disabled || item.current}>
                            <PaginationLink onClick={(event) => {
                                if(!item.disabled && !item.current){
                                    this.handleChange({target: { name: 'page', value: item.page }});
                                    // this.props.onChange(item.page, this.state.count);
                                }
                            } } >{ item.page }</PaginationLink>
                        </PaginationItem>
                    })
                }
                {
                    this.state.pages.length > 2 ?
                        <PaginationItem disabled={this.state.pages[this.state.pages.length - 1].disabled || this.state.pages[this.state.pages.length - 1].current }>
                            <PaginationLink  last onClick={(event) => {
                                this.handleChange({target: { name: 'page', value: this.state.pages.length }});
                            }} />
                        </PaginationItem>
                    : null
                }
            </Pagination>;
        };

        return (
            <div>
            {
                this.state.pages.length > 0 ? 
                <Row className="mt-10 mb-10">
                    <Col xs="12" sm="12" md="12" lg="9">
                        { this.state.pages.length > 1 ? <Buttons></Buttons> : null }
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="3">
                        {/* <div className="input-group">
                            <div className="input-group-prepend">
                                {this.state.label_counts}
                            </div>
                            <select name="count" onChange={this.handleChanges} className="custom-control">

                            </select>
                        </div> */}
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.state.label_counts}</InputGroupText>
                            </InputGroupAddon>
                            <CustomInput className="custom-control" type="select" name="count" id="count" onChange={ this.handleChange } value={this.state.count}>
                                    {
                                        this.state.counts.map((item, index) => {
                                            return <option key={index} value={item}>{item} resultados</option>
                                        })
                                    }
                            </CustomInput>
                        </InputGroup>
                    </Col>
                </Row> : null
            }
            </div>
        );
    }
}

export default PaginateButtonGroup;