import React, {useCallback, createRef} from 'react'
import {useDropzone} from 'react-dropzone'
import toast from './toast';

function MyDropzone(props) {

    const accept = props.accept || null;
    
    const types = ['image/jpeg', 'image/png', 'image/bmp', 'image/*', 'image/jpg'];

    const TWO_MiB = 2097152;
    const TEN_KB = 2097152;
    
    const maxSize = props.maxSize || TWO_MiB;
    const minSize = props.minSize || TEN_KB;
    
    const fileMatchSize = (file) => {
        return file.size <= maxSize && file.size >= minSize;
    };
    // --------- estilos para previews

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    };
      
    const thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    };
      
    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };
      
    const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
    };

    // -----------------------
    
    // const dropzoneRef = createRef();

    const style =  {
        width: '80px',
        height: '80px',
        margin: '5px',
        display: 'inline-block',
    };

    const onDrop = (acceptedFiles) => props.onDropFiles(acceptedFiles)

    // const onDrop = useCallback(acceptedFiles => {        
    //     props.onDropFiles(acceptedFiles);
    // }, []);

    const onDropRejected = useCallback(rejectFiles => {
        // console.log('==========>rejected files');
        // console.log(rejectFiles);
        var archivos = (rejectFiles||[]).map( (file) => { return file.name}).join(', ');
        /*rejectFiles.forEach( (file) =>{                        
            var type = file.type;            
            if(type){
                if( types.indexOf( file.type ) == -1 ){
                };
            }
        });*/

        return toast.warning('Algunos archivo no son correctos. Archivos: ' + archivos);
        //return toast.warning('El tamaño máximo de carga de archivos es de 2 MB. Archivos : ' + archivos);
        //props.onDropFiles(acceptedFiles);
    }, []);

    const params = { onDrop, accept, maxSize, onDropRejected };
    if (props.multiple != null && props.multiple === false){
        params.multiple = false;
    }
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone(params);

    if(props.onDragging && isDragActive){
        props.onDragging()
    }

    const DroppedFiles = (props) => {
        var files = (props.droppedFiles || []);
        // aqui podemos limpiar los valores reales seleccionados
        return files.map( (file, index) => {
            return <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                <img
                    src2s="{file.preview}"
                    src={ URL.createObjectURL(file) }
                    style={img}
                />
                </div>
            </div>
        });
    };

    // const files = acceptedFiles.map((file, index) => (
    //     <div style={thumb} key={file.name}>
    //         <div style={thumbInner}>
    //         <img
    //             src2s="{file.preview}"
    //             src={ URL.createObjectURL(file) }
    //             style={img}
    //         />
    //         </div>
    //     </div>
    // ));

    return (
        <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        {
            isDragActive ?
            <p>{props.placeholderDragging ||  'Suelte los archivos aqui...'}</p> :
            <p>{props.placeholder || 'Arrastre y suelte sus archivos aquí, o haga click para seleccionar archivos'}</p>
        }
        {
            <DroppedFiles droppedFiles={props.droppedFiles}></DroppedFiles>
        }
        {/* {<aside style={thumbsContainer}>
            {files}
        </aside> } */}
        </div>
    )
}

export default MyDropzone;