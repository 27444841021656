import React, { Component } from 'react';
import { Modal, ModalFooter, ModalHeader, ModalBody, Button, Fade } from 'reactstrap';
import api from './api';
import toast from './toast';

class ModalPreviewDocument extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            url: '',

            showBtnDescarga: false,
        };

        this.lastURL = null;
        this.refIframe        = React.createRef();
        this.getFormato       = this.getFormato.bind(this);
        this.descargarArchivo = this.descargarArchivo.bind(this);
        this.imprimirArchivo  = this.imprimirArchivo.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.url!==prevState.url){
            return { 
                url: nextProps.url,
            };
        }
        else return null;
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.url!==this.props.url && this.props.url){
            setTimeout(() => {
                this.getFormato(this.props.url);
            }, 500);
        }
    }
    
    getData(){
        
    }

    getFormato(urlArchivo){
        this.setState({ loading: true }, () => {
            api.axios({
                url: urlArchivo,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                return url;
            }).catch((error) => {
                toast.error('Ha ocurrido un error al generar la vista previa. '+error.message);
            }).then((url) => {
                this.setState({
                    loading: false,
                    generando_preview: false,
                    success_preview: url ? true : false,
                    showBtnDescarga: true,
                });

                if(url && this.refIframe){
                    this.refIframe.src = url;
                }
            });

        });
    }

    descargarArchivo(){
        
        let urlArchivo = this.props.url

        api.axios({
            url: urlArchivo,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            return url;
        }).catch((error) => {
            toast.error('Ha ocurrido un error al generar la vista previa. '+error.message);
        }).then((url) => {
            if(url){

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.props.title+'.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            }
        })

    }

    imprimirArchivo(){
        try {
            
            let id = "iframePreviewDocument"
            const iframe = document.frames ? document.frames[id] : document.getElementById(id);
            
            const iframeWindow = iframe.contentWindow || iframe;
    
            iframe.focus();
            iframeWindow.print();

        } catch (error) {
            toast.error('Error al imprimir directamente. Descargue el documento e imprima')
        }
    }

    render() {
        return (
            <div>
                <Modal backdrop="static" size="lg" style={{ maxWidth: '1600px', width: '80%' }} isOpen={this.props.isOpen} toggle={this.props.onClose} className={'modal-info'}>
                    <ModalHeader toggle={this.props.onClose}>{this.props.title || 'Ver documento'}</ModalHeader>
                    {
                        this.state.loading && (
                            <ModalBody>
                                <div className="text-center">
                                    <h5>Espere un momento por favor, se está generando el formato</h5>
                                    <i className="fa fa-spin fa-circle-o-notch"></i>
                                </div>
                            </ModalBody>
                        )
                    }

                    {
                        !this.state.loading && (
                            <div>

                                {/* {
                                    this.state.showBtnDescarga && 
                                    <div className="mt-15 mb-15 mx-2">
                                        <Button onClick={e => this.descargarArchivo() } type="button" color="primary"> <i className="fa fa-download"></i> Descargar formato</Button>
                                        {' '}
                                        <Button onClick={e => this.imprimirArchivo() } type="button" color="primary"> <i className="fa fa-print"></i> Imprimir formato</Button>
                                    </div>
                                } */}

                                <iframe
                                    id="iframePreviewDocument"
                                    ref={ (r) => {
                                        this.refIframe = r;
                                    }}
                                    title="Formato"
                                    style={{
                                        width: '100%',
                                        height: '500px',

                                    }}
                                />
                            </div>
                        )
                    }
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={this.props.onClose}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalPreviewDocument;