import { toast } from 'react-toastify';

export default {
	success: function(message, options){
		toast.success(message || 'Exitoso', options ? options : { position: toast.POSITION.TOP_RIGHT, autoClose: 2500});
	},
	error: function(message, options){
		toast.error(message || 'Error', options ? options : { position: toast.POSITION.TOP_RIGHT, autoClose: 6500});
	},
	warning: function(message, options){
		toast.warning(message || 'Alerta', options ? options : { position: toast.POSITION.TOP_RIGHT, autoClose: 4500});
	},
	info: function(message, options){
		toast.info(message || 'Información', options ? options : { position: toast.POSITION.TOP_RIGHT, autoClose: 2500});
	},
	POSITION: toast.POSITION,
};